import React from 'react';
import './Landing.css';

const SecondSection = () => {
  return (
    <div className="second-section">
      <text className="text-second-section">
        <h2>PLANIFICA, AUTOMATIZA Y GESTIONA</h2>
        <p>Lleva a otro nivel la ingeniería clínica de tu empresa proveedora de equipos médicos o institución de Salud (hospital, clínica, consultorio)</p>
      </text>

      <div className="statistics-second-section">
        <div className="statistics-item-second-section">
          <h3>100%</h3>
          <p>Digitaliza tus servicios, mantenimientos preventivos MP y mantenimientos correctivos MC</p>
        </div>

        <div className="statistics-item-second-section">
          <h3>100%</h3>
          <p>Control del historial de tus equipos e instrumental médico</p>
        </div>

        <div className="statistics-item-second-section">
          <h3>+2,000</h3>
          <p>Gestiona grandes cantidades de equipos médicos</p>
        </div>

        <div className="statistics-item-second-section">
          <h3>FACILITA</h3>
          <p>Estadísticas en tiempo real para toma de decisiones estratégicas</p>
        </div>
        
      </div>

    </div>
  );
};

export default SecondSection;