import React from 'react';
import './Landing.css';

function card() {
    return (
      <section id="solution">
      <div className="card-container">
        <div className="card">
          <h2>Softpital y empresas proveedoras de equipos médicos</h2>
          <a href="https://www.canva.com/design/DAGP4OygMxc/G587nk3WuhwnVA0EOskG8Q/view">CONOCE MÁS BENEFICIOS →</a>
        </div>
        <div className="card">
          <h2>Softpital e instituciones de salud (hospitales, clínicas, consultorios)</h2>
          <a href="https://www.canva.com/design/DAGP4BzhtSw/DMWH1qywUlxoWRe3dLDfXQ/view">CONOCE MÁS BENEFICIOS →</a>
        </div>
      </div>
      </section>
    );
  }
  
export default card;