import React from 'react';
import Nav from './components/Nav';
import FirstScreen from './components/FirstScreen';
import SecondSection from './components/SecondSection';
import Benefits from './components/Benefits';
import Testimonials from './components/Testimonials';
import BannerDemo from './components/BannerDemo';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import BannerDemoTwo from './components/BannerDemoTwo';
import Cards from './components/Cards.js';

function App() {
  return (
    <div className="App">
      <Nav />
      <FirstScreen />
      <SecondSection />
      <Benefits />
      <Cards />
      <Testimonials />
      <BannerDemo />
      <FAQ />
      <BannerDemoTwo />
     <Footer />
    </div>
  );
}

export default App;
