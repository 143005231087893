import React from 'react';
import './Landing.css';

const benefits = () => {
  return (
    <div className="container-benefits">
      <h1>DESCUBRE EL PODER DE LO SIMPLE CON CALIDAD SOFTPITAL EXCEPCIONA</h1>

      <div className="section-benefits">
        <img src="/laptop.png" alt="laptop" />
        <div className="text-benefits">
          <h4>Accede rápidamente al historial de tus equipos médicos</h4>
          <p>La dificultad para acceder rápidamente a la información puede retrasar los servicios y/o tareas. Tener acceso rápido a datos desde cualquier lugar permite una atención más eficiente y ágil.</p>
        </div>
      </div>

      <div className="section-benefits2">
        <div className="text-benefits2">
          <h4>Automatiza la planeación y controla tus servicios y mantenimientos de equipos médicos</h4>
          <p>Los procesos administrativos a menudo son pesados y consumen tiempo valioso. La optimización de estos procesos reduce significativamente el tiempo dedicado a tareas repetitivas, lo que te permite enfocarte en decisiones estratégicas y de mayor impacto.</p>
        </div>
          <img src="/laptop.png" alt="laptop" />
      </div>

      <div className="section-benefits">
        <img src="/laptop.png" alt="laptop" />
        <div className="text-benefits">
          <h4>Facilita la toma de decisiones estratégicas con mayor impacto, como el retorno de inversión de tus equipos y alargar su vida útil</h4>
          <p>La toma de decisiones informadas puede verse obstaculizada por la falta de análisis de datos adecuados. La posibilidad de contar con reportes y análisis te permite abordar situaciones con una perspectiva más clara y fundamentada. tomar decisiones estratégicas de mayor impacto.</p>
        </div>
      </div>
      
      {/* <div className='footer-benefits'>
        <h5>Descubre más beneficios que elevarán tu empresa o institución de salud</h5>
      </div>
        
        <div className="buttonone-benefits">
         <div className="contact-button-benefits"> 
            <a href="https://wa.me/8122130884" target="_blank" rel="noopener noreferrer">CONOCE MÁS BENEFICIOS</a> 
         </div>
        </div> */}
        
        <div className='demo-button-benefits'>
         <button
           className="demo-benefits"
           onClick={() => window.location.href = 'https://calendly.com/contacto-softpital/junta-softpital'}>
           PROBAR AHORA
         </button>
        </div>

    </div>
  );
};

export default benefits;
