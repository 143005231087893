import React from 'react';
import './Landing.css';

const Modal = ({ show, handleClose }) => {
  return (
    <div className={`modal ${show ? 'show' : ''}`}>
      <div className="modal-content">
        <span className="close" onClick={handleClose}>&times;</span>
        <img src="/logo_pop.gif" alt="GIF animado" />
        <h1>Estamos trabajando en una biblioteca digital con todos los libros y documentos que cualquier biomédico debe tener. ¡Próximamente disponible!</h1>
        <p>- Equipo Softpital</p>
      </div>
    </div>
  );
};

export default Modal;