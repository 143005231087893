import React from 'react';
import './Landing.css';

function FirstScreen() {
  return (
    <div className="first-screen">
      <div className="content-first">
        <div className="text-first">
          <h1>EL FUTURO PARA LA GESTIÓN DE LA TECNOLOGÍA BIOMÉDICA</h1>
          <p>Monitorea en tiempo real y centraliza los servicios y mantenimientos de tus equipos médicos.</p>

          <div className="buttons-first">
           <button
            className="blinking-button-first"
            onClick={() => window.location.href = 'https://calendly.com/contacto-softpital/junta-softpital'}>
            AGENDA UNA DEMO
           </button>

          <div className="contact-button-firts"> {/* Menú */}
            <a href="https://wa.me/8122130884" target="_blank" rel="noopener noreferrer">CHATEA CON UN EXPERTO →</a> 
          </div>

          </div>
        </div>

        <div className="sistemacomputo-img">
          <img src="/sistemacomputo.png" alt="sistemacomputo-img" />
        </div>
      </div>
    </div>
  );
}

export default FirstScreen;