import React from 'react';
import './Landing.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="company-info-footer">
          <img src="LogoCompletoBlanco.png" alt="Softpital Logo" className="footer-logo" />
          <p>Potencializa tu tecnología médica y extiende el ciclo de vida de tus equipos médicos.</p>
          <p>Monterrey, Nuevo León, México</p>
        </div>

        <div className="social-links-footer">
          <h1>Síguenos</h1> 
          <p>¡Síguenos en nuestras redes sociales para descubrir contenido exclusivo sobre el mundo de la ingeniería Biomédica!</p>
          <a href="https://www.linkedin.com/company/softpitalcompany/about/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
          <a href="https://www.instagram.com/softpitalcmms/" target="_blank" rel="noopener noreferrer">Instagram</a>
          <a href="https://www.facebook.com/profile.php?id=61564094526999" target="_blank" rel="noopener noreferrer">Facebook</a>
        </div>

        <div className="company-policies-footer">
          <h1>Compañía</h1>
          <a href="#privacy">Políticas de privacidad</a>
          <a href="#terms">Términos y condiciones</a>
        </div>
      </div>
      <div className="footer-bottom-footer">
        <p>Copyright © 2024 SOFTPITAL</p>
      </div>
    </footer>
  );
}

export default Footer;
