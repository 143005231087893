import React, { useState } from 'react';
import './Landing.css';

const FAQs = [
  { question: '¿SOFTPITAL es fácil de implementar?',
    answer: 'Sí, SOFTPITAL está diseñado para facilitar una implementación rápida y sencilla, optimizando el tiempo y los recursos de tu organización.' },
  { question: '¿Necesito capacitaciones tardadas para comprender el funcionamiento del SOFTPITAL?', 
    answer: 'No, el sistema es intuitivo y se puede aprender fácilmente con capacitaciones breves, minimizando el tiempo de adaptación.' },
  { question: '¿Cómo afecta SOFTPITAL en los costos de mis mantenimientos?', 
    answer: 'SOFTPITAL ayuda a reducir los costos de mantenimiento al mejorar la eficiencia y permitir una mejor gestión de recursos, evitando gastos innecesarios. Además de contar con estadisticas que te ayudan a crear estratégias sobre retorno de inversión y alargar la vida de tus equipos y tecnología médica.' },
  { question: '¿Puedo acceder a la información desde cualquier dispositivo?', 
    answer: 'Por supuesto, la accesibilidad es clave. Podras accedar a tus proyectos e información sin limitaciones, permitiendo que tu creatividad y productividad fluyan sin fronteras.' },
];

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="container-faq">
      <h2>PREGUNTAS MÁS FRECUENTES</h2>
      <ul>
        {FAQs.map((faq, index) => (
          <li key={index} className="item-faq">
            <div
              className={`question-faq ${activeIndex === index ? 'active' : ''}`}
              onClick={() => toggleFAQ(index)}
            >
              <span>{faq.question}</span>
              <i className="toggle-button-faq">{activeIndex === index ? '-' : '+'}</i> 
            </div>
            {activeIndex === index && <div className="answer-faq">{faq.answer}</div>}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FAQ;
